<template>
  <div>
    <b-row class="mt-4 mb-4">
      <b-col md="4" v-for="(team, index) in data" :key="index" class="mb-4">
        <TeamCard
          :teamDetails="team"
          :topicName="topicName"
          :themeColor="themeColor"
          @click="goToTeamDetails(team.id, team.topic_id)"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import TeamCard from "@/components/Teams/Cards/TeamCard.vue";
export default {
  props: ["data", "topicName", "themeColor"],
  components: {
    TeamCard
  },
  watch: {
    data(v) {
      if (v.length > 0) {
        this.$parent.$emit("hasData");
      }
    }
  },
  methods: {
    goToTeamDetails(teamId, topicId) {
      this.$router.push({
        name: "TeamDetail",
        params: {
          teamId: teamId,
          topicId: topicId
        },
        query: {
          topicName: this.topicName,
          themeColor: this.themeColor
        }
      });
    }
  }
};
</script>
