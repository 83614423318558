<template>
  <div class="team-card-wrappper">
    <b-card @click="onCardClick">
      <div class="d-flex">
        <div class="hdr-img" :style="`background: ${themeColor}`">
          <img
            src="@/assets/images/icons/users.svg"
            alt="teams"
            width="25"
            height="25"
          />
        </div>
        <b-card-text class="card-title__team" :title="teamDetails.name">{{
          teamDetails.name
        }}</b-card-text>
      </div>
      <b-card-text class="card-body__team">{{
        teamDetails.description
      }}</b-card-text>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-end">
            <!-- <div>
              <div class="d-flex">
                <img
                  src="@/assets/images/icons/title-letter.svg"
                  alt="teams"
                  width="auto"
                  height="auto"
                />
                <b-card-text class="card-topic-title__team">{{
                  topicName
                }}</b-card-text>
              </div>
            </div> -->

            <div v-if="teamDetails.language !== ''">
              <div class="d-flex">
                <img
                  src="@/assets/images/wrapper-components/course-card/language-icon.svg"
                  alt="language"
                  width="auto"
                  height="auto"
                />
                <b-card-text class="card__language">
                  {{ language }}
                </b-card-text>
              </div>
            </div>
            <div>
              <b-badge
                variant="primary"
                class="teams-count"
                v-if="teamDetails.is_joined"
              >
                {{ $t("teams.teams.member") }}
              </b-badge>
              <b-badge variant="light" class="teams-count" v-else>{{
                $t("teams.teams.x_members", { count: teamDetails.member_count })
              }}</b-badge>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    teamDetails: {
      type: Object
    },
    topicName: {
      type: String
    },
    themeColor: {
      type: String,
      default: "#e1ddff"
    }
  },
  computed: {
    ...mapGetters(["allLanguage"]),
    language() {
      let lang = this.teamDetails.language;
      if (lang && lang !== "") {
        let filteredLang = this.allLanguage.filter(l => l.id === lang);
        return filteredLang.length === 1 ? filteredLang[0].name : lang;
      }

      return "";
    }
  },
  methods: {
    onCardClick() {
      this.$emit("click");
    }
  }
};
</script>
<style lang="scss" scoped>
.team-card-wrappper {
  .card {
    background: #ffffff;
    border: 0;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: box-shadow 0.25s;

    &:hover {
      box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
        0px 1px 3px rgba(0, 0, 0, 0.3);
    }

    .card-body {
      padding: 16px;
    }

    .hdr-img {
      min-width: 40px;
      height: 40px;
      background: #e1ddff;
      border-radius: 8px;
      text-align: center;
      padding-top: 6px;
    }
    .card-title__team {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #191c1d;
      padding: 7px 0 10px 15px;

      @include text-ellipsis;
    }
    .card-body__team {
      font-size: 14px;
      line-height: 20px;
      color: #191c1d;
      min-height: 60px;
      @include line-clamp(3);
    }
    .card-topic-title__team {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #191c1d;
      margin-left: 5px;
      margin-top: 2px;
    }
    .card__language {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #191c1d;
      margin: 2px 10px 0 5px;
    }
    .teams-count {
      border: 1px solid #acacac;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #ffffff;
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      padding: 3px 10px;
      color: #44464e;
      &.badge-primary {
        border: 1px solid #0057e0;
        color: #0057e0;
      }
    }
    .team-avatars {
      margin-top: 20px;
      display: flex;
      ul {
        list-style: none;
        padding-left: 0px;
        max-width: 325px;
        li {
          display: inline-block;
          margin-right: 18px;
          span {
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }
}
// rtl layout
[dir="rtl"] {
  .team-card-wrappper {
    .card {
      .card-title__team {
        padding: 7px 15px 10px 0;
      }
      .card-body__team {
        text-align: right;
      }
      .card__language {
        margin: 2px 5px 0 10px;
      }
    }
  }
}
</style>
